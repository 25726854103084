

































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import decamelize from 'decamelize';
import {
  BAvatar, BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol, BFormTextarea, BInputGroup,
  BMedia,
  BMediaAside, BMediaBody,
  BRow, BTab, BTabs
} from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import SearchItem = CandidateAPI.Candidate.SearchItem;
import {VueGoodTable} from 'vue-good-table'

@Component({
  components: {
    VueGoodTable,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BInputGroup,
    BFormTextarea,
    BTabs,
    BTab,
    BButton,
    StatisticCardVertical
  }
})
export default class CandidateDashboard extends Vue {
  Columns: any = [
    {
      label: 'Full Name',
      field: 'fullName'
    },
    {
      label: 'Emails',
      field: 'emails'
    },
    {
      label: 'Registrations',
      field: 'registrations'
    }
  ];

  BulkAcodes = '';
  Found: SearchItem[] = []

  async Go() {
    let items: string[] = []
    for (const value of this.BulkAcodes.match(/[^\r\n]+/g).map(x => x.toLowerCase().trim())) {
      items.push(value)
    }

    const found = await this.$api.candidate.user.AcodeSearch(items);
    this.Found = found.items;
    //
  }
}
